import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`Hey, my name is Will. I do DevOps.`}</h3>
    <hr></hr>
    <p>{`For the past few years, I've been using Python and Javascript to facilitate event management for a Security Operations Center. I also used cloud native tools to develop and deploy infrastructure to run ETL pipelines.`}</p>
    <p>{`I've been using these tools extensively:`}</p>
    <ul>
      <li parentName="ul">{`Terraform`}</li>
      <li parentName="ul">{`Kubernetes`}</li>
      <li parentName="ul">{`Elasticsearch`}</li>
      <li parentName="ul">{`ServiceNow`}</li>
      <li parentName="ul">{`StreamSets`}</li>
      <li parentName="ul">{`Python`}</li>
      <li parentName="ul">{`Javascript`}</li>
      <li parentName="ul">{`WSL`}</li>
      <li parentName="ul">{`VSCode`}</li>
    </ul>
    <p>{`For more info, go to the `}<a parentName="p" {...{
        "href": "/about"
      }}>{`About`}</a>{` page.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      